.footer {
  position: relative;
  z-index: 5;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../resources/images/footerBg/Groupbg.svg);
    background-repeat: no-repeat;
    background-position: 50% 50px;
    background-size: cover;
    z-index: 0;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .stars1 {
    position: absolute;
    top: -5%;
    left: -29%;
  }
  .stars2 {
    position: absolute;
    top: -4%;
    right: -30%;
  }

  &__title {
    font-family: 'Dela Gothic One', sans-serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    max-width: 639px;
    margin: 32px auto 36px;
    position: relative;
    z-index: 5;

    @media (max-width: 500px) {
      font-size: 26px;
    }
  }

  &__copyright {
    font-family: 'Gilroy', sans-serif;
    padding-top: 256px;
    padding-bottom: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    position: relative;
    z-index: 3;

    @media (max-width: 500px) {
      padding-top: 50px;
      padding-bottom: 20px;
    }
  }
}
