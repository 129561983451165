.header__lang {
  position: absolute;
  z-index: 20;
  top: 200px;
  right: 50px;
  // margin-bottom: auto;
  // cursor: pointer;
  // width: 143px;
  // height: 61px;

  @media (max-width: 1000px) {
    display: flex;
    top: 40px;
    right: 10px;
  }

  &-text {
    font-family: 'Gilroy', sans-serif;
    // margin-left: 10px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    color: #ffffff;

    @media (max-width: 450px) {
      &.mobile {
        display: none;
      }
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    padding: 6px 8px;
    border-radius: 16px;
    transition: all 0.4s;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.4);
    border-radius: 16px;

    .header__lang-text {
      color: #fff !important;
    }

    &-arr {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 2px;
      color: #fff;
      width: 20px;

      @media (max-width: 450px) {
        &.mobile {
          display: none;
        }
      }

      svg {
        transform: rotate(0deg);
        transition: all 0.4s;
      }

      &.active {
        svg {
          transform: rotate(180deg);
          transition: all 0.4s;
        }
      }
    }
  }

  // &.active > .header__lang-title {
  //   border-bottom-left-radius: 0;
  //   border-bottom-right-radius: 0;
  //   transition: all 0.4s;
  // }

  &-dropdown {
    position: absolute;
    top: 120%;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: #f4f7f8;
    // border: 1px solid #ddd;
    border-radius: 5px;
    outline-offset: -1px;
    transition: all 0.4s;
    box-sizing: border-box;
    // width: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: 10;
    // width: 141px;
    // opacity: 0;

    @media (max-width: 450px) {
      left: -185%;
    }

    &.active {
      width: 141px;
      opacity: 1;
      pointer-events: auto;
      transition: all 0.4s;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 13px;
    transition: all 0.4s;
    cursor: pointer;
    width: 48px;
    height: 48px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 20;

    @media (max-width: 1000px) {
      width: 40px;
      height: 40px;
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:hover {
      background: rgba(0, 222, 255, 0.12);
      transition: all 0.3s;

      &::after {
        content: '';
        width: 36px;
        height: 1px;
        background-color: #00deff;
        position: absolute;
        top: 50%;
        right: -100%;

        @media (max-width: 1000px) {
          display: none;
        }
      }
    }

    &.active {
      background: rgba(0, 222, 255, 0.12);
      transition: all 0.3s;

      &::after {
        content: '';
        width: 36px;
        height: 1px;
        background-color: #00deff;
        position: absolute;
        top: 50%;
        right: -100%;

        @media (max-width: 1000px) {
          display: none;
        }
      }
    }
  }

  &-img {
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;

    img {
      width: 100%;
    }

    &.mobile {
      display: none;
      width: 30px;
      height: 30px;

      @media (max-width: 450px) {
        display: block;
      }
    }
  }
}
