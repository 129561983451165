/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 25, 2022 */

@font-face {
  font-family: 'Dela Gothic One';
  src: url('../../resources/fonts/delagothicone-regular-webfont.eot');
  src: url('../../resources/fonts/delagothicone-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../resources/fonts/delagothicone-regular-webfont.woff2')
      format('woff2'),
    url('../../resources/fonts/delagothicone-regular-webfont.woff')
      format('woff'),
    url('../../resources/fonts/delagothicone-regular-webfont.ttf')
      format('truetype'),
    url('../../resources/fonts/delagothicone-regular-webfont.svg#dela_gothic_oneregular')
      format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Golos';
  src: url('../../resources/fonts/golos_text_regular-webfont.eot');
  src: url('../../resources/fonts/golos_text_regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../resources/fonts/golos_text_regular-webfont.woff2')
      format('woff2'),
    url('../../resources/fonts/golos_text_regular-webfont.woff') format('woff'),
    url('../../resources/fonts/golos_text_regular-webfont.ttf')
      format('truetype'),
    url('../../resources/fonts/golos_text_regular-webfont.svg#golostext_regular')
      format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Golos';
  font-style: normal;
  font-weight: 600;
  /* Браузер сначала попробует найти шрифт локально */
  src: local('Golos'),
    /* Если не получилось, загрузит woff2 */
      url('../../resources/fonts/Golos-Text_Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Golos';
  font-style: normal;
  font-weight: 500;
  /* Браузер сначала попробует найти шрифт локально */
  src: local('Golos'),
    /* Если не получилось, загрузит woff2 */
      url('../../resources/fonts/Golos-Text_Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../resources/fonts/gilroy-bold-webfont.eot');
  src: url('../../resources/fonts/gilroy-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../resources/fonts/gilroy-bold-webfont.woff2') format('woff2'),
    url('../../resources/fonts/gilroy-bold-webfont.woff') format('woff'),
    url('../../resources/fonts/gilroy-bold-webfont.ttf') format('truetype'),
    url('../../resources/fonts/gilroy-bold-webfont.svg#gilroybold')
      format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../resources/fonts/gilroy-semibold-webfont.eot');
  src: url('../../resources/fonts/gilroy-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../resources/fonts/gilroy-semibold-webfont.woff2') format('woff2'),
    url('../../resources/fonts/gilroy-semibold-webfont.woff') format('woff'),
    url('../../resources/fonts/gilroy-semibold-webfont.ttf') format('truetype'),
    url('../../resources/fonts/gilroy-semibold-webfont.svg#gilroysemibold')
      format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../resources/fonts/gilroy-medium-webfont.eot');
  src: url('../../resources/fonts/gilroy-medium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../resources/fonts/gilroy-medium-webfont.woff2') format('woff2'),
    url('../../resources/fonts/gilroy-medium-webfont.woff') format('woff'),
    url('../../resources/fonts/gilroy-medium-webfont.ttf') format('truetype'),
    url('../../resources/fonts/gilroy-medium-webfont.svg#gilroymedium')
      format('svg');
  font-weight: 500;
  font-style: normal;
}
