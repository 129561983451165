.main {
  overflow: hidden;

  &__body {
    overflow: hidden;
    background: linear-gradient(180deg, #1c2436 0%, rgba(0, 0, 0, 0) 23.96%);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background: linear-gradient(180deg, #1c2436 0%, rgba(0, 0, 0, 0) 25%),
        linear-gradient(
          0deg,
          rgba(64, 101, 197, 0.15) 19.86%,
          rgba(64, 101, 197, 0.15) 21.59%,
          rgba(64, 101, 197, 0.15) 33.85%,
          rgba(85, 63, 201, 0.15) 42.86%,
          rgba(105, 30, 205, 0.15) 49.87%,
          rgba(42, 30, 128, 0.15) 97.93%
        );
    }
  }
  &__top {
    // background-image: url(../../resources/images/topBg/Back.svg);
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    padding-bottom: 50px;
    position: relative;
    z-index: 5;
    overflow: hidden;

    &-inner {
      position: relative;
      z-index: 1;
    }

    .moon {
      position: absolute;
      top: -45%;
      left: -38%;
      z-index: -1;
      max-width: 781px;
      max-height: 781px;
      width: 100%;
      opacity: 0.5;
      transform: scale(0.9);

      @media (max-width: 600px) {
        top: -30%;
        left: -60%;
      }

      animation: moon 9s ease-in-out infinite;

      @keyframes moon {
        0% {
          opacity: 0.5;
          transform: scale(0.98);
        }
        50% {
          opacity: 1;
          transform: scale(1);
        }
        100% {
          opacity: 0.5;
          transform: scale(0.98);
        }
      }
    }
    .stouns {
      position: absolute;
      top: 10%;
      left: -75%;
      z-index: -1;
    }
    .mountains {
      position: absolute;
      bottom: -100px;
      right: 0;
      z-index: -1;
    }
    .steam {
      position: absolute;
      top: -22%;
      right: -15%;
      z-index: -2;
      max-width: 1100px;
      max-height: 680px;
      width: 100%;
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .imgbg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -2;
      opacity: 0.5;
    }

    &-title {
      font-family: 'Dela Gothic One', sans-serif;
      font-weight: 400;
      font-size: 48px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 1px;
      color: #ffffff;
      max-width: 720px;
      margin: 0 auto;
      margin-bottom: 32px;

      div {
        color: #00deff;
      }

      @media (max-width: 500px) {
        font-size: 35px;
      }
    }

    &-links {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;
      position: relative;
      z-index: 5;

      @media (max-width: 500px) {
        margin-bottom: 35px;
      }
    }

    &-link {
      background: #ffffff;
      box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      max-width: 140px;
      height: 50px;
      width: 100%;
      padding: 10px 0 10px 15px;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: 24px;
      }

      &-img {
        margin-right: 5px;
        max-width: 24px;
        max-height: 24px;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;

        span {
          font-family: 'Golos', sans-serif;
          &:first-child {
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #282f4c;
          }

          &:last-child {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #282f4c;
          }
        }
      }
    }

    &-guys {
      display: flex;
      justify-content: center;

      img {
        max-width: 410px;
        max-height: 410px;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__card {
    padding-bottom: 150px;
    position: relative;
    z-index: 3;

    @media (max-width: 500px) {
      padding-bottom: 50px;
    }

    .cloud {
      position: absolute;
      position: absolute;
      top: 50%;
      right: -31%;
      z-index: 1;
    }

    &-title {
      font-family: 'Dela Gothic One', sans-serif;
      font-weight: 400;
      font-size: 36px;
      line-height: 150%;
      color: #ffffff;
      padding-top: 50px;
      padding-bottom: 60px;
      text-align: center;
      position: relative;
      z-index: 3;

      @media (max-width: 500px) {
        font-size: 30px;
        padding-top: 30px;
        padding-bottom: 40px;
      }

      span {
        color: #00deff;
      }
    }

    &-items {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 3;

      @media (max-width: 1070px) {
        flex-wrap: wrap;
      }

      @media (max-width: 450px) {
        flex-direction: column;
        align-items: center;
      }
    }

    &-item {
      max-width: 170px;

      &:not(:last-child) {
        margin-right: 24px;

        @media (max-width: 1070px) {
          margin-right: 24px;
        }
        @media (max-width: 576px) {
          margin-right: 12px;
        }
      }

      @media (max-width: 1070px) {
        flex: 0 1 33%;
        margin: 0 24px;
        margin-bottom: 24px;
      }

      @media (max-width: 576px) {
        flex: 0 1 50%;
        margin: 0 12px;
        margin-bottom: 24px;
      }

      @media (max-width: 450px) {
        max-width: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 35px;
      }

      &-img {
        max-width: 170px;
        max-height: 226px;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        margin-bottom: 24px;
        min-width: 150px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 16px;
          object-fit: cover;
        }

        @media (max-width: 450px) {
          max-width: none;
          max-height: none;
          width: 240px;
        }
      }

      &-text {
        font-family: 'Gilroy', sans-serif;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        color: #ffffff;

        span {
          color: #00deff;
        }
      }
    }
  }

  &__articles {
    position: relative;

    &.top {
      .stars1 {
        position: absolute;
        top: 8%;
        right: -30%;
        z-index: 3;
      }
      .stars2 {
        position: absolute;
        top: 51%;
        left: -40%;
        z-index: 3;
      }
      .cloud1 {
        position: absolute;
        top: 38%;
        left: 0;
        z-index: 1;
      }
      .cloud2 {
        position: absolute;
        top: 70%;
        right: -16%;
        z-index: 1;
      }
    }

    &.bot {
      .cloud1 {
        position: absolute;
        top: 38%;
        left: -16%;
        z-index: 1;
      }
      .rock {
        position: absolute;
        top: 24%;
        right: -45%;
        z-index: 3;
      }
    }
  }

  &__article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 150px;
    position: relative;
    z-index: 5;

    &:nth-child(even) {
      flex-direction: row-reverse;

      .main__article-info {
        margin-left: 24px;
        margin-right: 0;
      }

      @media (max-width: 576px) {
        flex-direction: row;
        flex-direction: column;

        .main__article-info {
          margin: 0 12px 24px;
        }
      }
    }

    @media (max-width: 576px) {
      flex-direction: column;
      margin-bottom: 50px;
    }

    &-info {
      max-width: 558px;
      width: 100%;
      margin-right: 24px;

      &-title {
        font-family: 'Dela Gothic One', sans-serif;
        font-weight: 400;
        font-size: 36px;
        line-height: 150%;
        color: #ffffff;
        margin-bottom: 24px;
        text-align: left;

        span {
          color: #00deff;
        }

        @media (max-width: 500px) {
          font-size: 30px;
        }
      }

      &-subtitle {
        font-family: 'Gilroy', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 170%;
        color: #9095af;
        text-align: left;
      }

      @media (max-width: 576px) {
        margin: 0 12px 24px;
        text-align: center;
      }
    }

    &-img {
      margin: 0 auto;
      max-width: 364px;
      max-height: 468px;

      img {
        width: 100%;
        height: 100%;

        position: relative;
        z-index: 2;
      }
    }
  }

  &__banner {
    display: flex;
    align-items: center;
    background: #967cff;
    border-radius: 32px;
    margin-bottom: 150px;
    padding: 0 80px;
    position: relative;
    min-height: 470px;
    z-index: 3;

    @media (max-width: 992px) {
      min-height: 350px;
    }

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      padding: 40px;
    }
    @media (max-width: 500px) {
      padding: 40px 20px;
      margin-bottom: 50px;
    }

    &-info {
      max-width: 485px;
      width: 100%;
      position: relative;
      z-index: 10;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-text {
        font-family: 'Dela Gothic One', sans-serif;
        font-weight: 400;
        font-size: 32px;
        line-height: 150%;
        color: #ffffff;
        margin-bottom: 36px;

        @media (max-width: 768px) {
          text-align: center;
        }
        @media (max-width: 500px) {
          font-size: 30px;
        }
      }

      &-links {
        display: flex;

        @media (max-width: 400px) {
          flex-direction: column;
        }
      }

      &-link {
        background: #282f4c;
        box-shadow: 3px 4px 0px rgba(255, 255, 255, 0.4);
        border-radius: 8px;
        width: 140px;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 10px 0 10px 12px;

        &:not(:last-child) {
          margin-right: 24px;

          @media (max-width: 400px) {
            margin-right: 0;
          }
        }

        @media (max-width: 400px) {
          margin-bottom: 24px;
        }

        &-img {
          max-width: 24px;
          max-height: 24px;
          margin-right: 5px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &-text {
          div {
            font-family: 'Golos', sans-serif;

            &:first-child {
              font-weight: 400;
              font-size: 10px;
              line-height: 12px;
              color: #ffffff;
              margin-bottom: 1px;
            }

            &:last-child {
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #ffffff;
            }
          }
        }
      }
    }

    &-img {
      max-width: 655px;
      max-height: 490px;
      width: 100%;
      height: 100%;
      transform: translate(0, 37px);

      img {
        max-width: 655px;
        max-height: 490px;
        height: 100%;
        width: 100%;
        transform: scale(1.3);

        @media (max-width: 768px) {
          transform: scale(1);
          margin-bottom: 24px;
        }
      }

      @media (max-width: 768px) {
        transform: translate(0, 0);
        max-width: 500px;
        max-height: 490px;
      }
    }
  }

  &__winners {
    position: relative;
    z-index: 3;

    &-title {
      font-family: 'Dela Gothic One';
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 150%;
      letter-spacing: 1px;
      color: #ffffff;
      margin-bottom: 60px;
      text-align: center;
      padding: 0 20px;

      @media (max-width: 500px) {
        font-size: 40px;
      }
    }

    &-places {
      margin-top: 148px;

      &-top {
        display: flex;
        justify-content: space-around;
        margin-bottom: 60px;

        @media (max-width: 900px) {
          flex-direction: column;
          align-items: center;
          margin-bottom: 0;
        }
      }
      &-left {
        display: flex;
        width: 100%;

        .main__winners-place {
          &:last-child {
            margin: 0 auto;
            margin-top: -48px;
          }
        }

        @media (max-width: 900px) {
          flex-direction: column-reverse;
          width: auto;
          align-items: center;
        }
      }
      &-bot {
        max-width: 752px;
        margin: 0 auto;

        // display: flex;
        // justify-content: space-evenly;

        // @media (max-width: 900px) {
        //   flex-direction: column;
        //   align-items: center;
        // }
      }
    }

    // &-list {
    //   width: 100%;

    //   tr:has(td) {
    //     border-bottom: 1px solid rgba(144, 149, 175, 0.1);

    //     &:last-child {
    //       border-bottom: none;

    //       td {
    //         &:not(:first-child) {
    //           padding-bottom: 0;
    //         }

    //         &:first-child {
    //           margin-bottom: 0;
    //         }
    //       }
    //     }

    //     td {
    //       font-family: 'Gilroy';
    //       font-style: normal;
    //       font-weight: 400;
    //       font-size: 20px;
    //       line-height: 23px;
    //       color: #ffffff;

    //       &:not(:first-child) {
    //         padding-bottom: 16px;
    //         padding-top: 16px;
    //       }

    //       &:first-child {
    //         background: rgba(255, 255, 255, 0.1);
    //         border-radius: 48px;
    //         width: 40px;
    //         height: 40px;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         margin-bottom: 16px;
    //         margin-top: 16px;
    //       }

    //       &:nth-child(2) {
    //         text-align: center;
    //         width: 33%;
    //       }

    //       &:nth-child(3) {
    //         text-align: right;
    //         width: 33%;
    //       }
    //     }
    //   }

    //   thead {
    //     tr {
    //       td {
    //         &:first-child {
    //           background: transparent;
    //         }
    //       }
    //     }
    //   }
    // }

    &-list {
      width: 100%;

      tr {
        border-bottom: 1px solid rgba(144, 149, 175, 0.1);

        &:last-child {
          border-bottom: none;

          td {
            &:not(:first-child) {
              padding-bottom: 0;
            }

            &:first-child {
              margin-bottom: 0;
            }
          }
        }

        td {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: #ffffff;

          &:not(:first-child) {
            padding-bottom: 16px;
            padding-top: 16px;
          }

          &:first-child {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 48px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;
            margin-top: 16px;
          }

          &:nth-child(2) {
            text-align: center;
            width: 33%;
          }

          &:nth-child(3) {
            text-align: right;
            width: 33%;
          }
        }
      }

      thead {
        tr {
          border-bottom: none !important;

          td {
            &:first-child {
              background: transparent;
            }
          }
        }
      }
    }

    &-place {
      max-width: 261px;

      @media (max-width: 900px) {
        margin-bottom: 50px !important;
      }

      &-name {
        text-align: center;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        color: #ffffff;
        margin-top: 24px;
      }

      &-id {
        font-family: 'Gilroy';
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        color: #9095af;
        margin-top: 12px;
      }

      &.first {
        .main__winners-place-img {
          &-text {
            span {
              color: #ffc700;
            }
          }
        }
      }

      &.third {
        .main__winners-place-img {
          &-text {
            span {
              color: #b06927;
            }
          }
        }
      }

      &-img {
        position: relative;

        &-text {
          position: absolute;
          top: 53%;
          left: 50%;
          transform: translate(-53%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #9095af;
          }
          img {
            margin-bottom: 8px;
          }
        }
      }
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;

      a {
        background: #00deff;
        border-radius: 10px;
        min-width: 278px;
        padding: 0 10px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #282f4c;
      }
    }
  }

  &__competition {
    display: flex;
    position: relative;
    z-index: 3;

    @media (max-width: 800px) {
      flex-direction: column-reverse;
    }

    &-left {
      flex: 0 1 50%;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 800px) {
        margin-right: 0;
      }

      .main {
        &__title {
          font-family: 'Dela Gothic One';
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 150%;
          letter-spacing: 1px;
          color: #ffffff;
          margin-bottom: 24px;

          span {
            color: #00deff;
          }

          @media (max-width: 800px) {
            text-align: center;
            font-size: 38px;
            line-height: 120%;
          }
        }

        &__subtitle {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 170%;
          color: #ffffff;
          margin-bottom: 36px;
          @media (max-width: 800px) {
            text-align: center;
          }
        }

        &__links {
          display: flex;

          @media (max-width: 800px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

          a {
            background: #00deff;
            border-radius: 10px;
            width: 194px;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 160%;
            text-align: center;
            color: #282f4c;
            padding: 0 10px;

            &:last-child {
              @media (max-width: 800px) {
                margin: 14px 0 0 0 !important;
              }
            }
          }
        }
      }
    }

    &-right {
      flex: 0 1 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 800px) {
        margin-bottom: 60px;
      }

      .main {
        &__guys {
          max-width: 400px;
          max-height: 400px;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
          }
        }
      }

      .timer {
        display: flex;

        &__wrapper {
          margin-top: 60px;
        }

        &__title {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 4.64968px;
          text-transform: uppercase;
          color: #00deff;

          @media (max-width: 500px) {
            font-size: 18px;
          }
        }

        .box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          min-width: 110px;
          width: 100%;

          @media (max-width: 500px) {
            min-width: 60px;
          }

          &:not(:last-child) {
            padding-right: 20px;
          }

          &:not(:last-child)::after {
            content: ':';
            position: absolute;
            top: 0;
            right: 3px;
            font-family: 'Dela Gothic One';
            font-style: normal;
            font-weight: 400;
            font-size: 48px;
            line-height: 70px;
            color: #ffffff;

            @media (max-width: 500px) {
              font-size: 30px;
            }
          }

          p {
            font-family: 'Dela Gothic One';
            font-style: normal;
            font-weight: 400;
            font-size: 48px;
            line-height: 70px;
            color: #ffffff;
            margin-bottom: 0;

            span {
              padding: 0 10px;
            }

            @media (max-width: 500px) {
              font-size: 30px;
            }
          }

          .text {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #9095af;
          }
        }
      }
    }
  }
}

.particles {
  z-index: 2;
}

.change__font__bold {
  font-family: 'Golos', sans-serif !important;
  font-weight: 600 !important;
}

.change__font__medium {
  font-family: 'Golos', sans-serif !important;
  font-weight: 500 !important;
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #00deff; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
