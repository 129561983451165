@primary: #1d4da5;
@success: #0ec74d;
@warning: #ff7a00;
@danger: #ff360a;
@white: #ffffff;

// text colors
@default-text-color: #040930;
@secondary-text-color: #718096;
@thirdly-text-color: #90969f;
@fourth-text-color: #1a1941;

// primary color
@primary-color: #ff7a41;

html[data-theme='dark'] {
  --huy: #1d4da5;
}

html[data-theme='light'] {
  --huy: #000;
}

// backhround colors
@default-btn-bg: #f4f7f8;
@default-bg-color: #f8f8f8;
@default-light-border-color: #b3b8c1;

// icons
@icomoon-font-family: 'icomoon';
@icomoon-font-path: 'fonts';

@icon-vehicles: '\e900';
@icon-security-cameraL: '\e90f';
@icon-dangerL: '\e91b';
@icon-location2: '\e948';

@fonts-path: '../../resources/fonts';
