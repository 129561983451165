body {
  font-weight: 500;
  background-color: #1c2436;
}

.d-flex {
  display: flex !important;
}

.align-center {
  align-items: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-center {
  justify-content: center !important;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding: 0 150px;
  width: 100%;
  @media (max-width: 1400px) {
    padding: 0 100px;
  }
  @media (max-width: 1280px) {
    padding: 0 50px;
  }
  @media (max-width: 992px) {
    padding: 0 20px;
  }
}
