.competition {
  &__top {
    background-image: url(../../resources/images/competition/competition-bg.png);
    background-repeat: no-repeat;
    padding-bottom: 50px;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 5;

    &-inner {
      &-title {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 65px;
        line-height: 135%;
        letter-spacing: 1px;
        color: #ffffff;
        max-width: 850px;
        margin-bottom: 36px;

        span {
          color: #00deff;
        }

        @media (max-width: 900px) {
          font-size: 50px;
        }
        @media (max-width: 500px) {
          font-size: 35px;
        }
      }

      &-img {
        max-width: 461px;
        max-height: 461px;
        margin-left: auto;
        transform: translate(0, -10px);
        // margin-top: -60px;

        img {
          width: 100%;
          height: 100%;
        }

        &.girl {
          max-width: 322px;
          max-height: 426px;
        }
      }
    }
  }

  &__body {
    background-color: #1c2436;
  }

  &__terms {
    padding-top: 100px;
    padding-bottom: 100px;

    @media (max-width: 900px) {
      padding-top: 70px;
      padding-bottom: 70px;
    }
    @media (max-width: 500px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &-title {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 65px;
      line-height: 135%;
      letter-spacing: 1px;
      color: #ffffff;
      max-width: 850px;
      margin: 0 auto;
      margin-bottom: 80px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;

      span {
        color: #00deff;
      }

      @media (max-width: 900px) {
        font-size: 50px;
        margin-bottom: 60px;
      }
      @media (max-width: 500px) {
        font-size: 30px;
        margin-bottom: 40px;
      }
    }

    &__tabs {
      display: flex;
      justify-content: space-between;
      max-width: 1160px;
      margin: 0 auto;
      margin-bottom: 100px;
      overflow: hidden;
      overflow-x: auto;
      padding: 0 20px;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: 500px) {
        margin-bottom: 40px;
      }
    }

    &__tab {
      min-width: 117px;
      min-height: 52px;
      background: rgba(144, 149, 175, 0.1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 160%;
      color: #9095af;
      transition: all 0.3s;
      cursor: pointer;
      border: none;
      pointer-events: none;

      &:not(:last-child) {
        margin-right: 26px;
      }

      &.passed {
        pointer-events: auto;
        border: 1px solid #00deff;
        background: transparent;
        color: #ffffff;
        cursor: pointer;
        transition: all 0.3s;
      }

      &.active {
        background: #00deff;
        color: #282f4c;
        transition: all 0.3s;

        // @media (min-width: 900px) {
        //   &:hover {
        //     background: #00deff;
        //     color: #282f4c;
        //     transition: all 0.3s;
        //   }
        // }
      }

      // @media (min-width: 900px) {
      //   &:hover {
      //     border: 1px solid #00deff;
      //     background: transparent;
      //     color: #ffffff;
      //     cursor: pointer;
      //     transition: all 0.3s;
      //   }
      // }
    }

    &__weeks {
      // max-height: 750px;
      // overflow: hidden;
      // height: 100vh;
      // overflow-y: auto;
      // position: relative;

      &::-webkit-scrollbar {
        display: none;
      }

      // &::before {
      //   content: "";
      //   // border-radius: 50%;
      //   background: red;
      //   min-width: 4px;
      //   max-width: 4px;
      //   min-height: 30px;
      //   max-height: 750px;
      //   position: -webkit-sticky;
      //   position: sticky;
      //   // top: calc(50vh - -15px);
      //   top: 0;
      //   left: calc(50% - 14.5px);
      //   z-index: 5;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   // position: sticky;
      //   // height: 100%;
      //   // width: 4px;
      //   // background: linear-gradient(180deg, #4840ff, #f197a1);
      //   // border-radius: 31px;
      //   // bottom: calc(50vh - -23px);
      //   // z-index: 1;
      // }

      &-line {
        position: absolute;
        min-height: 100%;
        max-height: 100%;
        width: 3px;
        // background: #d3d3d3;
        border-radius: 31px;
        left: calc(50% - 9px);
        top: 23px;

        div {
          position: sticky;
          height: 30px;
          width: 4px;
          background: linear-gradient(180deg, #00deff 0%, #9e00ff 100%);
          transition: all linear;
          border-radius: 31px;
          bottom: calc(50vh - -23px);
          z-index: 5;
          // transition: 0.3s linear;
        }
      }
    }

    &__weeks {
      position: relative;
    }

    &__week {
      display: flex;
      // opacity: 0.5;
      // transition: opacity 0.5;

      // &.active {
      //   opacity: 1;
      //   transition: opacity 0.5;
      // }

      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;

      &.active {
        opacity: 1;
        position: relative;
        z-index: 5;

        .competition__terms__week-title {
          span {
            &:first-child {
              -webkit-text-stroke: 1px #00deff;
            }

            &:last-child {
              color: #ffffff;
            }
          }
        }
      }

      // &::before {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   left: 50%;
      //   width: 15px;
      //   height: 15px;
      //   border-radius: 50%;
      //   background: #9095af;
      //   z-index: 6;
      // }

      // &::after {
      //   content: '';
      //   position: absolute;
      //   top: 23px;
      //   bottom: 8px;
      //   left: 50.55%;
      //   background: #d3d3d3;
      //   border-radius: 31px;
      //   width: 3px;
      // }

      // @media (max-width: 1145px) {
      //   flex-direction: column;

      //   &::before {
      //     left: 0;
      //     top: 15px;
      //   }

      //   &::after {
      //     left: 0.55%;
      //     top: 52px;
      //   }
      // }
      // @media (max-width: 600px) {
      //   &::after {
      //     left: 6px;
      //   }
      // }

      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
      }

      &-left {
        flex: 0 1 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-title {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 22px;
        // margin-top: -16px;
        // position: sticky;
        // top: 50%;

        span {
          &:first-child {
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 1px #9095af;
            font-style: italic;
          }

          &:last-child {
            color: #9095af;
            margin-left: 14px;

            @media (max-width: 550px) {
              margin-left: 0;
            }
          }
        }

        // @media (max-width: 1145px) {
        //   margin-top: 0;
        //   margin-left: 40px;
        // }

        @media (max-width: 1000px) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: 550px) {
          display: flex;
          flex-direction: column;
          font-size: 30px;

          span {
            text-align: center;
          }
        }
      }

      &-right {
        flex: 1 0 50%;
        padding-left: 70px;

        @media (max-width: 1400px) {
          padding-left: 40px;
        }

        @media (max-width: 600px) {
          padding-left: 15px;
        }
      }

      &-subtitle {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        line-height: 165%;
        font-size: 24px;
        color: #ffffff;

        @media (max-width: 1000px) {
          text-align: center;
          margin-bottom: 40px;
        }
      }

      &-screenshot {
        background: #161b26;
        border-radius: 16px;
        // margin-bottom: 102px;
        min-width: 480px;
        max-width: 499px;
        padding: 40px;
        padding-bottom: 0;
        position: relative;
        overflow: hidden;

        @media (max-width: 1200px) {
          padding: 20px;
          padding-bottom: 0;
        }
        @media (max-width: 600px) {
          min-width: 0;
          display: flex;
          justify-content: center;
          margin-bottom: 40px;
        }

        &-text {
          font-family: 'Gilroy';
          font-style: italic;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #9095af;
          max-width: 165px;
          position: absolute;
        }

        &-arrow {
          position: absolute;
        }

        &-img {
          margin-top: 90px;

          &.bigger {
            margin-top: 34px;

            @media (max-width: 600px) {
              margin-top: 70px;
            }
          }
        }
      }

      &:nth-child(even) {
        .competition__terms__week-screenshot-img {
          display: flex;
          justify-content: flex-end;
        }
      }

      &:first-child {
        .competition__terms__week-screenshot {
          &-text {
            top: 40px;
            right: 40px;

            @media (max-width: 600px) {
              left: 50px;
            }
          }
          &-arrow {
            top: 100px;
            right: 130px;

            @media (max-width: 600px) {
              top: 40px;
              right: 80px;
              transform: rotate(-83deg);
            }

            @media (max-width: 400px) {
              top: 40px;
              right: 40px;
              transform: rotate(-83deg);
            }
          }
        }
      }

      &:nth-child(2) {
        .competition__terms__week-screenshot {
          &-text {
            @media (max-width: 600px) {
              left: 70px;
            }
          }

          &-arrow {
            top: 120px;
            left: 130px;

            @media (max-width: 600px) {
              top: 40px;
              left: 10px;
              transform: rotate(45deg);
            }

            // @media (max-width: 400px) {
            //   top: 40px;
            //   right: 40px;
            // }
          }
        }
      }

      &:nth-child(3) {
        .competition__terms__week-screenshot {
          &-text {
            bottom: 100px;
            right: 40px;

            @media (max-width: 600px) {
              top: 20px;
              left: 20px;
            }
          }
          &-arrow {
            bottom: 190px;
            right: 160px;

            @media (max-width: 600px) {
              display: none;
            }
          }
        }
      }

      &:nth-child(4) {
        .competition__terms__week-screenshot {
          &-text {
            max-width: 200px;

            @media (max-width: 600px) {
              left: 120px;
            }
            @media (max-width: 400px) {
              left: 60px;
            }
          }
          &-arrow {
            top: 140px;
            left: 130px;

            @media (max-width: 600px) {
              top: 50px;
              left: 20px;
              transform: rotate(45deg);
            }
            @media (max-width: 400px) {
              left: 0;
            }
          }
        }
      }

      &:nth-child(5) {
        .competition__terms__week-screenshot {
          &-text {
            top: 40px;
            right: 40px;
            text-align: right;

            @media (max-width: 600px) {
              top: 25px;
            }
          }
          &-arrow {
            top: 60px;
            right: 220px;

            @media (max-width: 600px) {
              top: 40px;
              right: 200px;
            }
          }
        }
      }

      &:nth-child(6) {
        .competition__terms__week-screenshot {
          &-text {
            max-width: 200px;

            @media (max-width: 400px) {
              left: 60px;
            }
          }
          &-arrow {
            top: 120px;
            left: 140px;

            @media (max-width: 600px) {
              top: 40px;
              left: 10px;
              transform: rotate(45deg);
            }
          }
        }
      }

      &:nth-child(7) {
        .competition__terms__week-screenshot {
          &-text {
            bottom: 100px;
            right: 40px;

            @media (max-width: 600px) {
              top: 20px;
              left: 20px;
            }
          }
          &-arrow {
            bottom: 210px;
            right: 160px;

            @media (max-width: 600px) {
              display: none;
            }
          }
        }
      }

      &:nth-child(8) {
        .competition__terms__week-screenshot {
          &-text {
            @media (max-width: 600px) {
              max-width: 280px;
            }
          }
          &-arrow {
            top: 170px;
            left: 140px;

            @media (max-width: 600px) {
              top: 50px;
              left: 10px;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }

  &__prizes {
    margin-top: 30px;
    padding-bottom: 40px;

    &-title {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 65px;
      line-height: 135%;
      text-align: center;
      letter-spacing: 1px;
      color: #fff;
      max-width: 850px;
      margin: 0 auto;
      margin-bottom: 60px;

      span {
        color: #00deff;
      }

      @media (max-width: 900px) {
        font-size: 50px;
        margin-bottom: 50px;
      }
      @media (max-width: 500px) {
        font-size: 30px;
        margin-bottom: 40px;
      }
    }

    &-articles {
      display: flex;
      padding-bottom: 60px;

      @media (max-width: 1055px) {
        flex-direction: column;
      }

      &:last-child {
        @media (max-width: 500px) {
          padding-bottom: 0;
        }
      }
    }

    &-article {
      flex: 0 1 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:first-child {
        margin-right: 24px;

        @media (max-width: 1055px) {
          margin-right: 0;
          margin-bottom: 60px;
        }
      }

      &-items {
        padding: 26px;
        background: #161b26;
        border-radius: 16px;
        display: flex;
        flex-wrap: wrap;
        max-width: 461px;
        margin-bottom: 24px;

        @media (max-width: 500px) {
          flex-direction: column;
        }

        &.second {
          flex-direction: column;
        }

        &-double {
          display: flex;
          justify-content: space-between;
          margin-left: -5px;

          @media (max-width: 500px) {
            flex-direction: column;
          }

          .competition__prizes-article-item {
            height: 252px;
            width: 190px;

            @media (max-width: 500px) {
              width: 184px;
              height: 184px;
            }
          }
        }

        &-triple {
          display: flex;
          margin-left: -5px;

          @media (max-width: 500px) {
            flex-direction: column;
          }

          .competition__prizes-article-item {
            width: 120.5px !important;
            height: 120.5px !important;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              transform: scale(0.8);
            }

            @media (max-width: 500px) {
              width: 184px !important;
              height: 184px !important;

              img {
                transform: scale(1);
              }
            }
          }
        }

        &-fourth {
          display: flex;
          flex-direction: column;

          .competition__prizes-article-item {
            width: 390px;
            height: 186px;

            @media (max-width: 500px) {
              width: 184px;
              height: 184px;
            }
          }
        }
      }

      &-item {
        width: 184px;
        height: 184px;
        background: #1c2436;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-title {
        font-family: 'Gilroy';
        font-style: italic;
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 12px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px #00deff;
        font-style: italic;
        text-align: center;
      }

      &-subtitle {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 160%;
        text-align: center;
        color: #d3d3d3;
        max-width: 390px;
      }
    }
  }

  &__copyright {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: left;
    color: #ffffff;
    margin-top: -60px;

    @media (max-width: 850px) {
      margin-top: 0;
    }
  }

  &__footer {
    position: relative;
    padding-top: 100px;
    box-shadow: inset 0px 200px 200px -50px #1c2436;
    z-index: 1;

    @media (max-width: 900px) {
      padding-top: 80px;
    }
    @media (max-width: 500px) {
      padding-top: 50px;
    }
  }
}

.list {
  &__title {
    font-family: 'Dela Gothic One';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 150%;
    letter-spacing: 1px;
    color: #ffffff;
    margin: 0 auto;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
      font-size: 35px;
    }
  }
}
