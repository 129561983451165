.header {
  position: relative;
  z-index: 5;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 60px;
  }

  &__logo {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    cursor: pointer;

    &-img {
      width: 75px;
      height: 74px;
      margin-right: 10px;

      @media (max-width: 500px) {
        width: 55px;
        height: 54px;
      }

      img {
        width: 100%;
      }
    }

    &-name {
      display: flex;
      flex-direction: column;

      span {
        &:first-child {
          font-size: 40px;
          color: #fff;
          font-weight: bold;
          line-height: 22px;

          @media (max-width: 500px) {
            font-size: 30px;
          }
        }
        &:last-child {
          font-size: 30px;
          color: #00deff;
          font-weight: bold;
          line-height: 36px;
          @media (max-width: 500px) {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: 900px) {
      position: relative;
    }
  }

  &__nav {
    margin-right: 48px;
    margin-left: 24px;

    a {
      font-family: 'Gilroy', sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: #ffffff;

      &:not(:last-child) {
        margin-right: 36px;
      }
    }

    &-menu {
      display: none;
      color: #fff;
      margin-right: 24px;
      width: 34px;
      height: 34px;
    }

    &-plus {
      display: none;
      color: #fff;
      margin-right: 24px;
      width: 34px;
      height: 34px;
      transform: rotate(45deg);
    }

    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 10px;
      border-radius: 5px;
      position: absolute;
      top: 120%;
      right: 167px;
      margin: 0;
      width: 218px;
      opacity: 0;
      z-index: 0;
      transition: opacity 0.3s;

      a {
        text-align: left;
        color: #000;
        padding: 5px 10px;

        &:not(:last-child) {
          margin-right: 0;
          border-bottom: 1px solid #ddd;
        }
      }

      &.active {
        opacity: 1;
        z-index: 10;
        transition: opacity 0.3s;
      }

      &-menu {
        &.active {
          display: block;
        }
      }

      &-plus {
        &.active {
          display: block;
        }
      }
    }

    @media (max-width: 450px) {
      right: 0;
    }
  }
}
