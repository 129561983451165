.tender {
  padding-bottom: 50px;

  &__top {
    &-title {
      font-family: 'Dela Gothic One';
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 150%;
      color: #ffffff;
      text-align: center;
      margin-bottom: 20px;

      span {
        color: #00deff;
      }

      @media (max-width: 500px) {
        font-size: 38px;
        line-height: 120%;
      }

      @media (max-width: 400px) {
        font-size: 30px;
        line-height: 120%;
      }
    }

    &-period {
      font-family: 'Dela Gothic One';
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 150%;
      color: #00deff;
      text-align: center;
      margin-bottom: 35px;

      span {
        color: #ffffff;
      }

      @media (max-width: 500px) {
        font-size: 30px;
        line-height: 120%;
      }
      @media (max-width: 400px) {
        font-size: 24px;
        line-height: 120%;
      }
    }

    &-deadline {
      font-family: 'Dela Gothic One';
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 150%;
      color: #ffffff;
      margin-bottom: 30px;
      text-align: center;

      @media (max-width: 500px) {
        font-size: 26px;
        line-height: 120%;
      }

      @media (max-width: 400px) {
        font-size: 24px;
        line-height: 120%;
      }
    }

    &-organizer {
      font-family: 'Dela Gothic One';
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 150%;
      color: #ffffff;
      margin-bottom: 30px;
      text-align: center;

      span {
        color: #00deff;
      }

      @media (max-width: 500px) {
        font-size: 26px;
        line-height: 120%;
      }

      @media (max-width: 400px) {
        font-size: 24px;
        line-height: 120%;
      }
    }

    &-place {
      font-family: 'Dela Gothic One';
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 150%;
      color: #ffffff;
      margin-bottom: 30px;
      text-align: center;

      span {
        color: #00deff;
      }

      @media (max-width: 500px) {
        font-size: 26px;
        line-height: 120%;
      }

      @media (max-width: 400px) {
        font-size: 24px;
        line-height: 120%;
      }
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;

    @media (max-width: 500px) {
      margin-bottom: 50px;
    }
  }

  &__card {
    flex: 1 0 45%;
    background-color: #fff;
    border: 1px solid #00deff;
    border-radius: 15px;
    padding: 20px 10px 30px;
    margin: 0 10px 10px 0;
    box-shadow: none;
    transition: box-shadow 0.2s ease-in;

    &:hover {
      box-shadow: 0px 0px 15px 1px #00deff;
      transition: box-shadow 0.2s ease-in;
    }

    @media (max-width: 500px) {
      flex: 0 1 100%;
      margin: 0 0 10px 0;
    }

    &-title {
      color: #00deff;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
      line-height: 100%;

      @media (max-width: 500px) {
        font-size: 22px;
      }
    }

    ul {
      padding-left: 16px;
      margin-bottom: 0;

      li {
        font-size: 17px;
        line-height: 110%;
        font-weight: bold;
        padding-bottom: 3px;

        &::marker {
          color: #00deff;
        }
      }
    }
  }

  &__list {
    margin-bottom: 50px;

    &-title {
      font-family: 'Dela Gothic One';
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 150%;
      color: #ffffff;
      margin-bottom: 14px;
      text-align: center;

      @media (max-width: 500px) {
        font-size: 30px;
        line-height: 120%;
      }
    }

    &-subtitle {
      font-family: 'Dela Gothic One';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 130%;
      color: #ffffff;
      text-align: left;
      letter-spacing: 1px;
      margin-bottom: 30px;

      @media (max-width: 500px) {
        font-size: 16px;
        text-align: center;
      }
    }

    &-items {
      &-title {
        font-family: 'Dela Gothic One';
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 120%;
        color: #00deff;
        margin-bottom: 24px;
        text-align: center;
      }
    }

    &-item {
      border: 1px solid #00deff;
      border-radius: 5px;
      padding: 5px 10px;
      color: #9095af;
      font-size: 17px;
      line-height: 130%;
      margin-bottom: 14px;
      min-height: 60px;
      box-shadow: none;
      transition: box-shadow 0.2s ease-in;

      &:hover {
        box-shadow: 0px 0px 15px 1px #00deff;
        transition: box-shadow 0.2s ease-in;
      }

      span {
        color: #fff;
      }
    }
  }

  &__competitors {
    border-top: 1px solid #9095af;
    padding-top: 30px;

    &-title {
      color: #00deff;
      font-size: 20px;
      line-height: 110%;
      font-weight: 700;
      margin-bottom: 10px;
    }

    &-subtitle {
      color: #fff;
      font-size: 16px;
      line-height: 120%;
      font-weight: 500;
    }
  }
}
